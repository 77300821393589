import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type HeaderRootVariants = VariantProps<typeof headerRootVariants>;

export const headerRootVariants = cva({
  base: [
    // layout
    "max-w-full",
    // positioning
    "sticky top-0 z-40",
    // radii/shadow
    "shadow",
    // background
    "bg-white/90 backdrop-blur-xl",
  ],
  variants: {
    layout: {
      unset: null,
      mobileOnly: "grid-cols-main",
      desktopOnly: "grid-cols-main-md",
      responsive: "grid-cols-main md:grid-cols-main-md",
    },
  },
  compoundVariants: [
    {
      layout: ["mobileOnly", "desktopOnly", "responsive"],
      className: "grid grid-flow-row",
    },
  ],
  defaultVariants: {
    layout: "unset",
  },
});

// -----------------------------------------------------------------------------

export type HeaderContentVariants = VariantProps<typeof headerContentVariants>;

export const headerContentVariants = cva({
  variants: {
    layout: {
      unset: null,
      mobileOnly: "grid grid-cols-3 py-12px",
      desktopOnly:
        "grid grid-cols-[auto_1fr] grid-rows-2 gap-x-30px gap-y-18px py-20px",
      responsive: [
        "grid max-xl:grid-cols-3 max-xl:py-12px",
        "xl:grid-cols-[auto_1fr] xl:grid-rows-2 xl:gap-x-30px xl:gap-y-18px xl:py-20px",
      ],
    },
  },
  compoundVariants: [
    {
      layout: ["mobileOnly", "desktopOnly", "responsive"],
      className: "col-start-content-s col-end-content-e",
    },
  ],
  defaultVariants: {
    layout: "unset",
  },
});

export type HeaderContentSlotsVariants = VariantProps<
  typeof headerContentSlotsVariants
>;

export const headerContentSlotsVariants = headerContentVariants;

// -----------------------------------------------------------------------------

export type HeaderContentSlotVariants = VariantProps<
  typeof headerContentSlotVariants
>;

export const headerContentSlotVariants = cva({
  variants: {
    layout: {
      unset: null,
      mobileOnly: "self-center",
      desktopOnly: "grid items-end",
      responsive: "max-xl:self-center xl:grid xl:items-end",
    },
    slot: {
      "0": "",
      "1": "",
      "2": "",
    },
  },
  compoundVariants: [
    {
      layout: "mobileOnly",
      slot: "0",
      className: "justify-self-center text-center",
    },
    {
      layout: "mobileOnly",
      slot: "1",
      className: "-order-1 justify-self-start text-left",
    },
    {
      layout: "mobileOnly",
      slot: "2",
      className: "justify-self-end text-right",
    },
    {
      layout: "desktopOnly",
      slot: "0",
      className: "justify-start text-left [grid-area:1/1/3/2]",
    },
    {
      layout: "desktopOnly",
      slot: "1",
      className: "justify-end text-right [grid-area:2/2/3/3]",
    },
    {
      layout: "desktopOnly",
      slot: "2",
      className: "justify-end text-right [grid-area:1/2/2/3]",
    },
    {
      layout: "responsive",
      slot: "0",
      className: [
        "max-xl:justify-self-center max-xl:text-center",
        "xl:justify-start xl:text-left xl:[grid-area:1/1/3/2]",
      ],
    },
    {
      layout: "responsive",
      slot: "1",
      className: [
        "max-xl:-order-1 max-xl:justify-self-start max-xl:text-left",
        "xl:justify-end xl:text-right xl:[grid-area:2/2/3/3]",
      ],
    },
    {
      layout: "responsive",
      slot: "2",
      className: [
        "text-right max-xl:justify-self-end",
        "xl:justify-end xl:[grid-area:1/2/2/3]",
      ],
    },
  ],
  defaultVariants: {
    layout: "unset",
  },
});

// -----------------------------------------------------------------------------

export type HeaderTitleVariants = VariantProps<typeof headerTitleVariants>;

export const headerTitleVariants = cva({
  base: "whitespace-nowrap text-balance text-h3 leading-none",
});

// -----------------------------------------------------------------------------

export type HeaderCloseVariants = VariantProps<typeof headerCloseVariants>;

export const headerCloseVariants = cva({
  base: [
    // layout
    "inline-flex size-36px items-center justify-center rounded-md p-0",
    // background/foreground
    "bg-transparent text-shade-40",
    // focus/hover/active
    "hover:bg-shade-8 focus:bg-shade-8 active:bg-shade-8",
    // animation
    "transition-colors",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
  ],
});
