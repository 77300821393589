import { cva, type VariantProps } from "./style.utils";

export type PopoverContentVariants = VariantProps<
  typeof popoverContentVariants
>;

export const popoverContentVariants = cva({
  base: [
    // positioning
    "relative z-50",
    // focus unset
    "outline-none",

    // animation in
    "data-[state=open]:animate-in",
    "data-[state=open]:fade-in-0",
    "data-[state=open]:zoom-in-95",

    // animation out
    "data-[state=closed]:animate-out",
    "data-[state=closed]:fade-out-0",
    "data-[state=closed]:zoom-out-95",

    // animation slide-in
    "data-[side=top]:slide-in-from-bottom-2",
    "data-[side=bottom]:slide-in-from-top-2",
    "data-[side=left]:slide-in-from-right-2",
    "data-[side=right]:slide-in-from-left-2",
  ],
  variants: {
    palette: {
      unset: null,
      black: [
        // background
        "bg-shade-100",
        // foreground
        "text-white",
      ],
      white: [
        // border
        "border border-shade-15",
        // background
        "bg-white",
        // foreground
        "text-shade-100",
      ],
    },
    size: {
      unset: null,
      sm: "max-w-288px px-5px py-3px text-sm md:max-w-288px",
      md: "max-w-vw-sm p-20px md:max-w-vw-md",
    },
  },
  compoundVariants: [
    {
      palette: ["black", "white"],
      className: "rounded-md shadow-md",
    },
  ],
  defaultVariants: {
    palette: "white",
    size: "md",
  },
});

// -----------------------------------------------------------------------------

export type PopoverCloseVariants = VariantProps<typeof popoverCloseVariants>;

export const popoverCloseVariants = cva({
  base: [
    // layout
    "size-12px",
    // positioning
    "absolute right-1px top-1px",
    // foreground
    "text-neutral-60",
  ],
});

// -----------------------------------------------------------------------------

export type PopoverArrowRootVariants = VariantProps<
  typeof popoverArrowRootVariants
>;

export const popoverArrowRootVariants = cva({
  base: [
    // layout
    "flex",
    // layout control arrow position and size
    "h-10px items-end",
    // layout control arrow position and shadow masking
    "w-30px justify-center",
    // layout arrow masking
    "overflow-hidden",
  ],
});

export type PopoverArrowVariants = VariantProps<typeof popoverArrowVariants>;

export const popoverArrowVariants = cva({
  base: [
    // layout
    "size-20px shrink-0",
    // position
    "origin-bottom-right -translate-x-1/2 rotate-45",
    // radii/shadow
    "rounded-sm shadow-sm",
  ],
  variants: {
    palette: {
      unset: null,
      black: [
        // background
        "bg-shade-100",
      ],
      white: [
        // border
        "border border-shade-15",
        // background
        " bg-white",
      ],
    },
  },
  defaultVariants: {
    palette: "white",
  },
});
