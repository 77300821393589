import NextLink from "next/link";
import { forwardRef } from "react";
import {
  HeaderContent,
  HeaderContentSlots,
  HeaderRoot,
  type HeaderContentSlotsProps,
  type HeaderRootProps,
  type HeaderRootRef,
} from "./header.components";
import {
  headerCloseVariants,
  headerTitleVariants,
  type HeaderCloseVariants,
  type HeaderTitleVariants,
} from "./header.styles";
import { Icon } from "./icon";

// -----------------------------------------------------------------------------

export type HeaderRef = HeaderRootRef;

export interface HeaderProps extends HeaderRootProps {}

const Header = forwardRef<HeaderRef, HeaderProps>(
  ({ children, layout, ...props }, ref) => {
    return (
      <HeaderRoot {...props} layout={layout} ref={ref}>
        <HeaderContent layout={layout}>{children}</HeaderContent>
      </HeaderRoot>
    );
  },
);

Header.displayName = "Header";

export { Header };

// -----------------------------------------------------------------------------

export type HeaderWithSlotsRef = HeaderRootRef;

export interface HeaderWithSlotsProps
  extends Omit<HeaderProps, "children">,
    Pick<HeaderContentSlotsProps, "slot0" | "slot1" | "slot2"> {}

const HeaderWithSlots = forwardRef<HeaderWithSlotsRef, HeaderWithSlotsProps>(
  ({ slot0, slot1, slot2, layout, ...props }, ref) => {
    return (
      <HeaderRoot {...props} layout={layout} ref={ref}>
        <HeaderContentSlots
          layout={layout}
          slot0={slot0}
          slot1={slot1}
          slot2={slot2}
        />
      </HeaderRoot>
    );
  },
);

HeaderWithSlots.displayName = "HeaderWithSlots";

export { HeaderWithSlots };

// -----------------------------------------------------------------------------

export type HeaderTitleRef = React.ElementRef<"h1">;

export interface HeaderTitleProps
  extends React.ComponentPropsWithoutRef<"h1">,
    HeaderTitleVariants {}

const HeaderTitle = forwardRef<HeaderTitleRef, HeaderTitleProps>(
  ({ className, ...props }, ref) => {
    return (
      <h1 {...props} className={headerTitleVariants({ className })} ref={ref} />
    );
  },
);

HeaderTitle.displayName = "HeaderTitle";

export { HeaderTitle };

// -----------------------------------------------------------------------------

export type HeaderCloseRef = React.ElementRef<typeof NextLink>;

export interface HeaderCloseProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    HeaderCloseVariants {}

const HeaderClose = forwardRef<HeaderCloseRef, HeaderCloseProps>(
  ({ children, className, href, ...props }, ref) => (
    <NextLink
      {...props}
      href={href}
      className={headerCloseVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-close" size="lg" />
      <span className="sr-only">{children}</span>
    </NextLink>
  ),
);

HeaderClose.displayName = "HeaderClose";

export { HeaderClose };
