"use client";

import {
  Anchor as RadixPopoverAnchor,
  Arrow as RadixPopoverArrow,
  Close as RadixPopoverClose,
  Content as RadixPopoverContent,
  Portal as RadixPopoverPortal,
  Root as RadixPopoverRoot,
  Trigger as RadixPopoverTrigger,
  type PopoverAnchorProps as RadixPopoverAnchorProps,
  type PopoverArrowProps as RadixPopoverArrowProps,
  type PopoverCloseProps as RadixPopoverCloseProps,
  type PopoverContentProps as RadixPopoverContentProps,
  type PopoverPortalProps as RadixPopoverPortalProps,
  type PopoverProps as RadixPopoverRootProps,
  type PopoverTriggerProps as RadixPopoverTriggerProps,
} from "@radix-ui/react-popover";
import { forwardRef } from "react";
import { Icon } from "./icon";
import {
  PopoverArrowRootVariants,
  popoverArrowRootVariants,
  popoverArrowVariants,
  popoverCloseVariants,
  popoverContentVariants,
  type PopoverArrowVariants,
  type PopoverCloseVariants,
  type PopoverContentVariants,
} from "./popover.styles";

// -----------------------------------------------------------------------------

export type PopoverRootRef = React.ElementRef<typeof RadixPopoverRoot>;

export interface PopoverRootProps extends RadixPopoverRootProps {}

export { RadixPopoverRoot as Popover };

// -----------------------------------------------------------------------------

export type PopoverTriggerRef = React.ElementRef<typeof RadixPopoverTrigger>;

export interface PopoverTriggerProps extends RadixPopoverTriggerProps {}

export const PopoverTrigger = RadixPopoverTrigger;

// -----------------------------------------------------------------------------

export type PopoverAnchorRef = React.ElementRef<typeof RadixPopoverAnchor>;

export interface PopoverAnchorProps extends RadixPopoverAnchorProps {}

export const PopoverAnchor = RadixPopoverAnchor;

// -----------------------------------------------------------------------------

export type PopoverPortalRef = React.ElementRef<typeof RadixPopoverPortal>;

export interface PopoverPortalProps extends RadixPopoverPortalProps {}

export const PopoverPortal = RadixPopoverPortal;

// -----------------------------------------------------------------------------

export type PopoverContentRef = React.ElementRef<typeof RadixPopoverContent>;

export interface PopoverContentProps
  extends RadixPopoverContentProps,
    PopoverContentVariants {}

const PopoverContent = forwardRef<PopoverContentRef, PopoverContentProps>(
  ({ className, palette, sideOffset = 4, size, ...props }, ref) => (
    <RadixPopoverContent
      {...props}
      sideOffset={sideOffset}
      className={popoverContentVariants({ className, palette, size })}
      ref={ref}
    />
  ),
);

PopoverContent.displayName = "PopoverContent";

export { PopoverContent };

// -----------------------------------------------------------------------------

export type PopoverCloseRef = React.ElementRef<typeof RadixPopoverClose>;

export interface PopoverCloseProps
  extends RadixPopoverCloseProps,
    PopoverCloseVariants {}

const PopoverClose = forwardRef<PopoverCloseRef, PopoverCloseProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixPopoverClose
        {...props}
        className={popoverCloseVariants({ className })}
        ref={ref}
      >
        <Icon name="ms-close" className="size-full" />
        <span className="sr-only">Close</span>
      </RadixPopoverClose>
    );
  },
);

PopoverClose.displayName = "PopoverClose";

export { PopoverClose };

// -----------------------------------------------------------------------------

export type PopoverArrowRef = React.ElementRef<typeof RadixPopoverArrow>;

export interface PopoverArrowProps
  extends Omit<RadixPopoverArrowProps, "asChild">,
    PopoverArrowRootVariants,
    Pick<PopoverArrowVariants, "palette"> {}

const PopoverArrow = forwardRef<PopoverArrowRef, PopoverArrowProps>(
  ({ className, palette, ...props }, ref) => {
    return (
      <RadixPopoverArrow {...props} asChild ref={ref}>
        {/* fragment needed to avoid svg props passed to div */}
        <>
          <div className={popoverArrowRootVariants({ className })}>
            <div className={popoverArrowVariants({ palette })} />
          </div>
        </>
      </RadixPopoverArrow>
    );
  },
);

PopoverArrow.displayName = "PopoverArrow";

export { PopoverArrow };
