import { forwardRef } from "react";
import { Button, type ButtonProps, type ButtonRef } from "./button";
import { Icon } from "./icon";
import {
  Sheet,
  SheetCloseIconButton,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetMain,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
  type SheetContentProps,
  type SheetProps,
  type SheetTriggerRef,
} from "./sheet";

// -----------------------------------------------------------------------------

export type NavButtonToggleMenuRef = ButtonRef;

export interface NavButtonToggleMenuProps
  extends Omit<
    ButtonProps,
    "className" | "children" | "iconOnly" | "size" | "variant"
  > {}

const NavButtonToggleMenu = forwardRef<
  NavButtonToggleMenuRef,
  NavButtonToggleMenuProps
>((props, ref) => {
  return (
    <Button {...props} variant="ghost" size="sm" iconOnly ref={ref}>
      <Icon name="custom-menu" />
      <span className="sr-only">Toggle Primary Navigation</span>
    </Button>
  );
});

NavButtonToggleMenu.displayName = "NavButtonToggleMenu";

export { NavButtonToggleMenu };

// -----------------------------------------------------------------------------

export type NavSheetRef = SheetTriggerRef;

export interface NavSheetProps
  extends Omit<SheetProps, "children">,
    Pick<
      SheetContentProps,
      | "onCloseAutoFocus"
      | "onEscapeKeyDown"
      | "onInteractOutside"
      | "onOpenAutoFocus"
      | "onPointerDownOutside"
    > {
  elementMain: React.ReactElement;
  elementFooter?: React.ReactElement;
}

const NavSheet = forwardRef<NavSheetRef, NavSheetProps>(
  (
    {
      elementFooter,
      elementMain,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onInteractOutside,
      onOpenAutoFocus,
      onPointerDownOutside,
      ...props
    },
    ref,
  ) => {
    return (
      <Sheet {...props}>
        <SheetTrigger asChild ref={ref}>
          <NavButtonToggleMenu />
        </SheetTrigger>
        <SheetPortal>
          <SheetOverlay />
          <SheetContent
            onCloseAutoFocus={onCloseAutoFocus}
            onEscapeKeyDown={onEscapeKeyDown}
            onInteractOutside={onInteractOutside}
            onOpenAutoFocus={onOpenAutoFocus}
            onPointerDownOutside={onPointerDownOutside}
            aria-describedby={undefined}
          >
            <SheetHeader>
              <SheetTitle className="sr-only">Site Navigation</SheetTitle>
              <SheetCloseIconButton className="left-18px right-auto" />
            </SheetHeader>
            <SheetMain>{elementMain}</SheetMain>
            <SheetFooter>{elementFooter}</SheetFooter>
          </SheetContent>
        </SheetPortal>
      </Sheet>
    );
  },
);

NavSheet.displayName = "NavSheet";

export { NavSheet };
