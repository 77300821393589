"use client";

import { useTimeout } from "#app/lib/useTimeout";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { navMenuContentLinkVariants } from "#ui/nav-menu.styles";
import { NavRoute } from "#ui/nav.utils";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRootProps,
  PopoverTrigger,
  type PopoverTriggerRef,
} from "#ui/popover";
import NextLink from "next/link";
import { forwardRef, useState } from "react";

export type PopoverProfileAndSettingsRef = PopoverTriggerRef;

export interface PopoverProfileAndSettingsProps
  extends Omit<PopoverRootProps, "defaultOpen" | "open" | "onOpenChange"> {
  activePath: string;
  route: NavRoute;
}

const PopoverProfileAndSettings = forwardRef<
  PopoverProfileAndSettingsRef,
  PopoverProfileAndSettingsProps
>(({ activePath, route, ...props }, ref) => {
  const [open, setOpen] = useState(false);

  const { handleStartTimeout, handleClearTimeout } = useTimeout(
    () => setOpen(false),
    500,
  );

  return (
    <Popover {...props} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild ref={ref}>
        <Button
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => handleStartTimeout()}
          variant="ghost"
          iconOnly
          className="w-auto px-3px"
        >
          <Icon name={route.iconName} palette="accent" size="lg" />
          <span className="sr-only">{route.element}</span>
          <Icon name="ms-arrow-drop-down" className="text-shade-100" />
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          onMouseEnter={() => handleClearTimeout()}
          onMouseLeave={() => handleStartTimeout()}
          size="unset"
          className="py-10px"
        >
          <ul>
            {route.paths?.map((el) => (
              <li key={el.id}>
                <NextLink
                  href={el.path}
                  onClick={() => setOpen(false)}
                  className={navMenuContentLinkVariants({
                    activePathMatch: el.path === activePath,
                  })}
                >
                  {el.element}
                </NextLink>
              </li>
            ))}
          </ul>
          <PopoverArrow />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
});

PopoverProfileAndSettings.displayName = "PopoverProfileAndSettings";

export { PopoverProfileAndSettings };
