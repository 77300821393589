import { forwardRef } from "react";
import {
  headerContentSlotVariants,
  headerContentSlotsVariants,
  headerContentVariants,
  headerRootVariants,
  type HeaderContentSlotVariants,
  type HeaderContentSlotsVariants,
  type HeaderContentVariants,
  type HeaderRootVariants,
} from "./header.styles";

// -----------------------------------------------------------------------------

export type HeaderRootRef = React.ElementRef<"header">;

export interface HeaderRootProps
  extends React.ComponentPropsWithoutRef<"header">,
    HeaderRootVariants {}

const HeaderRoot = forwardRef<HeaderRootRef, HeaderRootProps>(
  ({ className, layout, ...props }, ref) => {
    return (
      <header
        {...props}
        className={headerRootVariants({ className, layout })}
        ref={ref}
      />
    );
  },
);

HeaderRoot.displayName = "HeaderRoot";

export { HeaderRoot };

// -----------------------------------------------------------------------------

export type HeaderContentRef = React.ElementRef<"div">;

export interface HeaderContentProps
  extends React.ComponentPropsWithoutRef<"div">,
    HeaderContentVariants {}

const HeaderContent = forwardRef<HeaderContentRef, HeaderContentProps>(
  ({ className, layout, ...props }, ref) => {
    return (
      <div
        {...props}
        className={headerContentVariants({ className, layout })}
        ref={ref}
      />
    );
  },
);

HeaderContent.displayName = "HeaderContent";

export { HeaderContent };

// -----------------------------------------------------------------------------

export type HeaderContentSlotsRef = React.ElementRef<"div">;

export interface HeaderContentSlotsProps
  extends React.ComponentPropsWithoutRef<"div">,
    HeaderContentSlotsVariants,
    Omit<HeaderContentSlotVariants, "slot"> {
  slot0: NonNullable<React.ReactNode>;
  slot1?: React.ReactNode;
  slot2?: React.ReactNode;
}

const HeaderContentSlots = forwardRef<
  HeaderContentSlotsRef,
  HeaderContentSlotsProps
>(({ className, layout, slot0, slot1, slot2, ...props }, ref) => {
  return (
    <div
      {...props}
      className={headerContentSlotsVariants({ className, layout })}
      ref={ref}
    >
      <div className={headerContentSlotVariants({ layout, slot: "0" })}>
        {slot0}
      </div>
      <div className={headerContentSlotVariants({ layout, slot: "1" })}>
        {slot1}
      </div>
      <div className={headerContentSlotVariants({ layout, slot: "2" })}>
        {slot2}
      </div>
    </div>
  );
});

HeaderContentSlots.displayName = "HeaderContentSlots";

export { HeaderContentSlots };
