import { useCallback, useRef } from "react";

export function useTimeout(callback: () => void, ms: number) {
  const id = useRef<number>();

  const handleStartTimeout = useCallback(() => {
    if (id.current) window.clearTimeout(id.current);
    id.current = window.setTimeout(callback, ms);
  }, [callback, ms]);

  const handleClearTimeout = useCallback(() => {
    window.clearTimeout(id.current);
  }, []);

  return { handleStartTimeout, handleClearTimeout };
}
