import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { TimeSpan } from "#app/lib/TimeSpan";
import { bolApi } from "#app/lib/fetchClient";
import { getBlob, getJson, postJson } from "@/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export type SecureDocumentData = {
  documentId: number;
  lastAccessDate: Date;
  isSender: boolean;
  fileSize: number;
  mimeType: string;
  uploadDate: Date;
  expiryDate: Date;
  displayFilename: string;
};
export type DocumentExchange = {
  document: Document;
  notificationNote: string | null;
};

export type Document = {
  displayFilename: string;
  filename: string;
  file: string;
  mimeType: string;
};

type FinishDocumentGroupRequest = {
  notificationNote: string | null;
  documentIds: number[];
};

type PostDocumentNoGroupRequest = {
  document: Document;
  notificationNote: string | null;
};

const GATEWAY_PATH = "ExternalSecureDocument/v1";

export async function getDocumentsReceived(): Promise<SecureDocumentData[]> {
  const result = await getJson<SecureDocumentData[]>(
    `/${GATEWAY_PATH}/Documents/Received`,
  );
  return result;
}

export async function getDocumentsSent(): Promise<SecureDocumentData[]> {
  const result = await getJson<SecureDocumentData[]>(
    `/${GATEWAY_PATH}/Documents/Sent`,
  );
  return result;
}

export async function getDocumentContent(documentId: number): Promise<Blob> {
  const result = await getBlob(
    `/${GATEWAY_PATH}/Documents/Content?documentId=${documentId}`,
  );
  return result;
}

export async function postDocumentToRepCode(
  document: DocumentExchange,
): Promise<void> {
  const result = await postJson(
    `/${GATEWAY_PATH}/Documents/ToRepCode`,
    document,
  );
  return result;
}

export async function postCreateDocumentGroup(
  request: FinishDocumentGroupRequest,
): Promise<void> {
  const result = await postJson(
    `/${GATEWAY_PATH}/Documents/ToRepCode/Group`,
    request,
  );
  return result;
}

export async function postDocumentNoDocumentGroup(
  request: PostDocumentNoGroupRequest,
): Promise<number> {
  const result = await postJson<number>(
    `/${GATEWAY_PATH}/Documents/ToRepCode/Group/Document`,
    request,
  );
  return result;
}

const STALE_TIME = TimeSpan.fromMinutes(1).totalMilliseconds;

export const secureDocumentQueryKeys = createQueryKeys("securedocs", {
  documentsReceived: {
    queryKey: null,
    queryFn: () =>
      bolApi
        .get(`${GATEWAY_PATH}/Documents/Received`)
        .json<SecureDocumentData[]>(),
    contextQueries: {
      unreadCount: {
        queryKey: null,
        queryFn: () =>
          bolApi.get(`${GATEWAY_PATH}/Documents/Received/Count`).json<number>(),
      },
    },
  },
  documentsSent: {
    queryKey: null,
    queryFn: () =>
      bolApi.get(`${GATEWAY_PATH}/Documents/Sent`).json<SecureDocumentData[]>(),
  },
});

export function useSecureDocsUnreadCountQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsReceived._ctx.unreadCount,
      staleTime: STALE_TIME,
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentsReceivedQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsReceived,
      staleTime: STALE_TIME,
      placeholderData: [],
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentsSentQuery() {
  const jwt = useJwt();
  return useQuery(
    queryOptions({
      ...secureDocumentQueryKeys.documentsSent,
      staleTime: STALE_TIME,
      placeholderData: [],
      enabled: jwt.segment !== Segment.NotLoggedIn,
    }),
  );
}

export function useDocumentsContent() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (documentId: number) =>
      bolApi
        .get(`${GATEWAY_PATH}/Documents/Content?documentId=${documentId}`)
        .blob(),
    onSettled: () =>
      queryClient.invalidateQueries(secureDocumentQueryKeys.documentsReceived),
  });
}

export function useDocumentDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (documentId: number) =>
      bolApi.delete(`${GATEWAY_PATH}/Documents?documentId=${documentId}`),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: secureDocumentQueryKeys._def,
      }),
  });
}
